/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { randomBytes } from 'crypto';
import { ProjectGetResponse, ReportListResponse, File, UnitType, BedroomType, FinishType, ReportList, Note,
  ResidentialUnit, CommercialUnit, OtherUnit, Country, SaleStatus, LeadGetResponse, ScenarioStatus, DateOfSellMonth,
  currencyByCountry, InputDataContextInterface, CoverImage, Log, SquareUnit, CreateDocumentRequestType, FinanceUploadFileType } from '../../../../types';
import { apiFetch } from '../api';
import { AppThunk } from '../store';
import { setComparablesFilter, setComparablesLimit, setComparablesPage, setFiles, setLocalArea, setLocalAreaSchools, setLocalAreaStations,
  setLocalMarket, setLocationGeoCodes, setNotes, setProjectId, setProjectName, setReportList, setReportListAll, setReportListAllStatus,
  setReportListStatus, setResultData, setScenarioId, setSelectedComparables, setSensitivityAnalyses, setAveragePricesStat, setTeaserReportId,
  setRiskProfile, setLoading, setScenarioCreatedAt, setUsers, setSelectorSq_ft_Sq_m, setEditingData, setHubspotLists, setHubspotContacts,
  setIsOwned, setLoadStatus, setShowAddress, setScenarioName, setIsShowAll, setComparablesList, setMaxComparables, setIsBlockApisUsage,
  setLeadId, setScenarioStatus, setScenarioShortId, setTemplateId, setPresetConstructionId, setPresetPurchaseId, setPresetFinancingId,
  setAllScenariosNames, setLeadFiles, setAssetsId, setLeadImages, setLogs, setInitialStateKeepReportList, setIsSaving, setLeadName } from './calculate';
import { parseResultData } from '../../shared';

type createProjectParams = {
  projectId?: string;
  callback?: (projectId: string) => void
};

export const createProject = ({ projectId, callback }: createProjectParams): AppThunk => async (dispatch, getState) => {
  try {
    if (!projectId) {
      dispatch(setIsSaving(true));
    }

    const userId = getState().Auth.user?.id;

    if (!userId) {
      return;
    }

    dispatch(setLoading(true));
    const name = getState().calculate.analysePage?.editingData?.project_name || '';

    const res = await apiFetch(`storage/project${projectId ? `/${projectId}` : ''}`, {
      method: 'post',
      body: JSON.stringify({ name }),
      headers: { 'Content-Type': 'application/json', userId },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    } else {
      if (!projectId) {
        const leadId = getState().calculate.leadId;

        if (leadId) {
          const resLead = await apiFetch(`storage/lead/${leadId}`, {
            method: 'PATCH',
            body: JSON.stringify({ projectId: res.projectId }),
            headers: { 'Content-Type': 'application/json', userId },
            returnError: true,
          });

          if ('error' in resLead) {
            console.error('Error while updating lead', resLead.error);
            toast(`Error while updating lead: ${resLead.error}`, { type: 'error' });
          }
        }

        toast('New project created');
      } else {
        toast('Project saved');
      }

      if (name) {
        dispatch(updateAsset({ projectName: name }));
      }

      dispatch(setProjectId(res.projectId));
      callback?.(res.projectId);
      dispatch(setLoading(false));
      dispatch(setIsSaving(false));
    }
  } catch (error) {
    console.error(error);
    toast('Unable to create project', { type: 'error' });
    dispatch(setLoading(false));
    dispatch(setIsSaving(false));
  }
};

type createScenarioParams = {
  projectId?: string;
  parentId?: string;
  scenarioId?: string;
  resultData?: any;
  files?: File[];
  teaserReportId?: string;
  riskProfile?: number;
  showAddress?: boolean;
  status?: ScenarioStatus;
  templateId?: string;
  name?: string;
  inputData?: InputDataContextInterface;
  sensitivityAnalyses?: any;
  logs?: Log[];
  presetConstructionId?: string;
  presetPurchaseId?: string;
  presetFinancingId?: string;
  callback?: (scenarioShortId: number) => void
};

export const createScenario = ({ projectId, parentId, scenarioId, resultData, files, teaserReportId, riskProfile,
  showAddress, status, templateId: templateIdParam, name: nameParam, inputData, sensitivityAnalyses, logs: logsParam,
  presetConstructionId: presetConstructionIdParam, presetPurchaseId: presetPurchaseIdParam, presetFinancingId: presetFinancingIdParam,
  callback }: createScenarioParams): AppThunk => async (dispatch, getState) => {

  try {
    if (!scenarioId) {
      dispatch(setIsSaving(true));
    }

    const userId = getState().Auth.user?.id;

    if (!userId) {
      return;
    }

    const resultRaw = (() => {
      try {
        return resultData ? JSON.parse(resultData) : JSON.parse(getState().calculate.resultData);
      } catch {
        return {};
      }
    })();

    dispatch(setLoading(true));

    const sensitivityAnalysis = sensitivityAnalyses || getState().calculate.sensitivityAnalyses;
    const { selectedComparables, comparablesFilter, page, limit, isShowAll } = getState().calculate.analysePage?.comparablesService;
    const localArea = getState().calculate.analysePage?.localArea?.data;
    const schools = getState().calculate.analysePage?.localAreaSchools;
    const stations = getState().calculate.analysePage?.localAreaStations;
    const localMarket = getState().calculate.analysePage?.localMarket;
    const locationService = getState().calculate.analysePage?.locationService;
    const notes = getState().calculate.notes || [];
    const projectSqftSqm = getState().calculate.analysePage?.selectorSq_ft_Sq_m;
    const templateId = templateIdParam || getState().calculate?.templateId;
    const presetConstructionId = presetConstructionIdParam || getState().calculate?.presetConstructionId;
    const presetPurchaseId = presetPurchaseIdParam || getState().calculate?.presetPurchaseId;
    const presetFinancingId = presetFinancingIdParam || getState().calculate?.presetFinancingId;
    const name = nameParam || getState().calculate?.scenarioName;
    const leadId = getState().calculate.leadId;
    const logs = (logsParam || getState().calculate.logs)?.map?.(({ userName, ...item }) => item) || [];
    const allScenariosNames = getState().calculate?.allScenariosNames;

    const calculate = { input: inputData || resultRaw.input, output: resultRaw.output };
    const comparables = { selectedComparables: selectedComparables?.map((item) => item._id) || [], comparablesFilter, page, limit, isShowAll };

    const res = await apiFetch(`storage/scenario${scenarioId ? `/${scenarioId}` : ''}`, {
      method: 'post',
      body: JSON.stringify({ projectId, parentId, calculate, sensitivityAnalysis, comparables, localArea, schools,
        stations, localMarket, locationService, teaserReportId, riskProfile, projectSqftSqm, showAddress, status,
        templateId, presetConstructionId, presetPurchaseId, presetFinancingId, name, leadId, logs }),
      headers: { 'Content-Type': 'application/json', userId },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    } else {
      dispatch(setScenarioId(res.scenarioId));
      dispatch(setScenarioShortId(res.scenarioShortId));
    }

    for (const note of notes) {
      try {
        await apiFetch('storage/note', {
          method: 'post',
          body: JSON.stringify({ ...note, scenarioId: res.scenarioId }),
          headers: { 'Content-Type': 'application/json', userId },
          returnError: true,
        });
      } catch (error) {
        console.error(error);
        toast('Error while saving note', { type: 'error' });
      }
    }

    for (const file of files || []) {
      try {
        await apiFetch(`storage/file/scenario/${res.scenarioId}?section=${file.section}&name=${file.name}&type=${file.type}`, {
          method: 'post',
          headers: { userId },
          body: file.file,
          returnError: true,
        });
      } catch (error) {
        console.error(error);
        toast('Error while saving file', { type: 'error' });
      }
    }

    if (!scenarioId) {
      toast('New scenario created');
    }

    callback?.(res.scenarioShortId);

    if (res.name) {
      dispatch(setScenarioName(res.name));
      dispatch(setAllScenariosNames(Array.from(new Set([...allScenariosNames, res.name]))));
    }

    dispatch(setLoading(false));
    dispatch(setIsSaving(false));
  } catch (error) {
    console.error(error);
    toast('Unable to create scenario', { type: 'error' });
    dispatch(setLoading(false));
    dispatch(setIsSaving(false));
  }
};

type GetScenarioParams = { scenarioId: string, loadCalcInput: boolean } | { teaserReportId: string, loadCalcInput: boolean };

export const getScenario = (params: GetScenarioParams): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setLoadStatus('load'));
    const userId = getState().Auth.user?.id;

    if (!userId) {
      return;
    }

    const res: ProjectGetResponse = ('scenarioId' in params) ? await apiFetch(`storage/project/${params.scenarioId}`, {
      method: 'get',
      headers: { userId },
      returnError: true,
    }) : await apiFetch(`storage/project/${params.teaserReportId}/teaser`, {
      method: 'get',
      headers: { userId },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    }

    const propertyIds = res.scenario?.comparables?.selectedComparables || [];

    // TBD: add types
    const country = (res.scenario?.calculate?.input as { country: Country })?.country;

    const selectedComparables = propertyIds.length > 0  ? await (async () => {
      try {
        const response =  await apiFetch(`comparables/details`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ propertyIds, country }),
        });

        return response;
      } catch (error) {
        console.error(error);
        return [];
      }
    })() : [];

    if (params.loadCalcInput) {
      dispatch(setEditingData({ ...res.scenario?.calculate?.input }));
    }

    dispatch(setEditingData({
      country: res.scenario?.calculate?.input.country,
      currency: res.scenario?.calculate?.input.currency || currencyByCountry[res.scenario?.calculate?.input?.country],
    }));

    dispatch(setResultData(JSON.stringify({ input: res.scenario?.calculate?.input, output: res.scenario?.calculate?.output })));
    dispatch(setSensitivityAnalyses(res.scenario?.sensitivityAnalysis));
    dispatch(setLocalArea({ data: res.scenario?.localArea }));
    dispatch(setLocalAreaStations(res.scenario?.stations));
    dispatch(setLocalAreaSchools(res.scenario?.schools));
    dispatch(setLocalMarket(res.scenario?.localMarket));
    dispatch(setComparablesFilter(res.scenario?.comparables?.comparablesFilter));
    dispatch(setSelectedComparables(selectedComparables));
    dispatch(setIsShowAll(res?.scenario?.comparables?.isShowAll === undefined ? true : res?.scenario?.comparables?.isShowAll));
    dispatch(setScenarioCreatedAt(res.scenario?.createdAt));
    if (typeof res.scenario?.comparables?.page === 'number') dispatch(setComparablesPage(res.scenario?.comparables?.page));
    if (typeof res.scenario?.comparables?.limit === 'number') dispatch(setComparablesLimit(res.scenario?.comparables?.limit));
    dispatch(setProjectName(res.project?.name));
    dispatch(setNotes(res.notes));
    dispatch(setFiles(res.files));
    dispatch(setLocationGeoCodes(res.scenario.locationService?.getGeoCodes));
    dispatch(setProjectId(res.scenario.projectId));
    dispatch(setScenarioId(res.scenario._id));
    dispatch(setTeaserReportId(res.scenario.teaserReportId));
    dispatch(setUsers(res.users));
    dispatch(setRiskProfile(res.scenario.riskProfile || 5));
    dispatch(setSelectorSq_ft_Sq_m(res.scenario.projectSqftSqm));
    dispatch(setIsOwned(res.isOwned));
    dispatch(setShowAddress(res.scenario?.showAddress));
    dispatch(setScenarioName(res.scenario?.name));
    dispatch(setScenarioStatus(res.scenario?.status));
    dispatch(setScenarioShortId(res.scenario?.id));
    dispatch(setTemplateId(res.scenario?.templateId));
    dispatch(setPresetConstructionId(res.scenario?.presetConstructionId));
    dispatch(setPresetPurchaseId(res.scenario?.presetPurchaseId));
    dispatch(setPresetFinancingId(res.scenario?.presetFinancingId));
    dispatch(setAllScenariosNames(res.allScenariosNames || []));
    dispatch(setLeadId(res.scenario?.leadId));
    dispatch(setLogs(res.scenario?.logs?.map?.(({ _id, ...item }) => item) || []));

    if (res.scenario?.leadId) {
      dispatch(getLead(res.scenario?.leadId, 'data'));
    }

    dispatch(setLoadStatus('success'));
  } catch (error) {
    console.error(error);
    toast('Unable to get project', { type: 'error' });
    dispatch(setLoadStatus('error'));
  }
};

export const saveData = (callback: (id: number) => void): AppThunk => async (dispatch, getState) => {
  const inputData = getState().calculate.analysePage.editingData;
  const projectId = getState().calculate.projectId;
  const scenarioId = getState().calculate.scenarioId;

  const updateScenario = (projectId: string) => {
    dispatch(createScenario({ projectId, scenarioId, inputData, callback }));
  };

  if (!projectId) {
    dispatch(createProject({ callback: updateScenario }));
  } else {
    updateScenario(projectId);
  }
};

interface UpdateDataProps {
  input?: Partial<InputDataContextInterface>;
  output?: any;
  selectorSq_ft_Sq_m?: SquareUnit;
  presetConstructionId?: string;
  presetPurchaseId?: string;
  presetFinancingId?: string;
  scenarioName?: string;
  callback?: (scenarioId: number) => void;
}

export const updateData = (data: UpdateDataProps): AppThunk => async (dispatch, getState) => {

  const { input, output, presetConstructionId, presetPurchaseId, presetFinancingId, scenarioName: name, callback } = data;

  const resultData = output || getState().calculate.resultData;

  dispatch(setResultData(resultData));

  const inputData = { ...getState().calculate.analysePage.editingData, ...input };
  const projectId = getState().calculate.projectId;
  const scenarioId = getState().calculate.scenarioId;

  // input update logic

  const totalFloorAreaChanged = 'total_floor_area' in inputData;
  const hasTotalFloorAreaCost = inputData?.construction_costs_array.some((item) => 'total_floor_area_cost' in item && item.total_floor_area_cost);

  if ((inputData?.delayed_payment_purchase || 0) > (inputData?.site_purchase_price || 0)) {
    inputData.delayed_payment_purchase = inputData?.site_purchase_price;
  }

  if (totalFloorAreaChanged && hasTotalFloorAreaCost) {
    inputData.construction_costs_array = inputData.construction_costs_array.map((item) => ({
      ...item,
      total_cost: 'total_floor_area_cost' in item && item.total_floor_area_cost ?
        (inputData.total_floor_area || 0) * (item.total_floor_area_cost || 0) : item.total_cost,
    }));
  }

  // updating project and scenario

  const updateScenario = (projectId: string) => {
    dispatch(createScenario({
      projectId,
      scenarioId,
      inputData: {
        ...inputData,
        ...input,
      },
      resultData,
      presetConstructionId,
      presetPurchaseId,
      presetFinancingId,
      name,
      callback,
    }));
  };

  dispatch(setEditingData(inputData));

  if (!projectId) {
    dispatch(createProject({ callback: updateScenario }));
  } else {
    updateScenario(projectId);
  }
};

export const copyScenario = (scenarioId: string, callback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    const userId = getState().Auth.user?.id;

    if (!userId) {
      return;
    }

    const res = await apiFetch(`storage/scenario/copy/${scenarioId}`, {
      method: 'get',
      headers: { userId },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    }

    callback?.();

    toast('Scenario copied', { type: 'success' });
  } catch (error) {
    console.error(error);
    toast('Unable to copy scenario', { type: 'error' });
  }
};

export const deleteScenario = (scenarioId: string): AppThunk => async (dispatch, getState) => {
  try {
    const userId = getState().Auth.user?.id;
    const role = getState().Auth.user?.role;
    const reportList: ReportList | undefined = getState().calculate.reportList;
    const reportListAll: ReportList | undefined = getState().calculate.reportListAll;

    if (!userId || (role === 'admin' && !reportListAll)) {
      return;
    }

    const res = await apiFetch(`storage/scenario/${scenarioId}`, {
      method: 'delete',
      headers: { userId },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    }

    if (reportList) {
      dispatch(setReportList({
        ...reportList, projects: reportList?.projects?.map(item => ({
          ...item,
          scenarios: item.scenarios ? item.scenarios.filter(scenario => scenario._id !== scenarioId) : [],
        })),
      }));
    }

    if (reportListAll) {
      dispatch(setReportListAll({
        ...reportListAll, projects: reportListAll?.projects?.map(item => ({
          ...item,
          scenarios: item.scenarios ? item.scenarios.filter(scenario => scenario._id !== scenarioId) : [],
        })),
      }));
    }

    toast('Scenario deleted', { type: 'success' });

    if (getState()?.calculate?.scenarioId === scenarioId) {
      dispatch(setInitialStateKeepReportList());
    }
  } catch (error) {
    console.error(error);
    toast('Unable to delete scenario', { type: 'error' });
  }
};

export const getReportList = (all: boolean, page: number, records: number): AppThunk => async (dispatch, getState) => {
  try {
    const setStatus = all ? setReportListAllStatus : setReportListStatus;

    dispatch(setStatus('load'));

    const userId = getState().Auth.user?.id;

    if (!userId) {
      return;
    }

    const res: ReportListResponse = await apiFetch(`storage/project/list${all ? '/all' : '/user'}/${page}/${records}`, {
      method: 'get',
      headers: { userId },
      returnError: true,
    });

    if ('error' in res) {
      setStatus('error');
      throw new Error(res.error);
    }

    const updatedProjects = res.projects.map(project => {
      return { ...project, isOpened: project.isOpened ?? false };
    });
    res.projects = updatedProjects;

    if (all) {
      dispatch(setReportListAll(res));
    } else {
      dispatch(setReportList(res));
    }

    dispatch(setStatus('success'));
  } catch (error) {
    console.error(error);
    toast('Unable to get project list', { type: 'error' });
  }
};

interface GetAveragePricesProps {
  unitType?: UnitType;
  bedrooms?: BedroomType;
  finishType?: FinishType;
  callback: (sale?: number, rent?: number) => void;
}

export const getAveragePrices = ({ unitType, bedrooms, finishType, callback }: GetAveragePricesProps): AppThunk => async (dispatch, getState) => {
  const country = getState().calculate.analysePage.editingData.country;
  const postcode = getState().calculate.analysePage?.locationService?.getGeoCodes?.postcode;
  const [longitude, latitude] = getState().calculate.analysePage.locationService?.getGeoCodes?.geometry?.coordinates || [];

  const isInvalidUKProps = (!postcode || !finishType || finishType === 'N/A') && country === 'UK';
  const isInvalidSWEProps = (!latitude || !longitude) && country === 'SWE';
  const isInvalidUnitType = !unitType || unitType === 'Unspecified';
  const isInvalidBedrooms = bedrooms === undefined || bedrooms === 'N/A' || bedrooms === 'Unspecified';

  if (isInvalidUKProps || isInvalidSWEProps || isInvalidUnitType || isInvalidBedrooms) {
    callback();
    return;
  }

  const data = await apiFetch('average-prices', {
    method: 'post',
    returnError: true,
    body: country === 'UK' ? JSON.stringify({ postcode, country, unitType, finishType, bedrooms }) : JSON.stringify({ country, latitude, longitude, unitType, bedrooms }),
  });

  if (!('error' in data)) {
    callback(data.sale, data.rent);
  }
};

interface GetSizePricesProps {
  unitType?: UnitType;
  bedrooms?: BedroomType;
  callback: (sale?: number, rent?: number) => void;
}

export const getAverageSize = ({ unitType, bedrooms, callback }: GetSizePricesProps): AppThunk => async (dispatch, getState) => {
  const postcode = getState().calculate.analysePage?.locationService?.getGeoCodes?.postcode;
  const selectorSq_ft_Sq_m = getState().calculate.analysePage.selectorSq_ft_Sq_m;

  if (!postcode || !unitType || bedrooms === undefined || unitType === 'Unspecified' || bedrooms === 'Unspecified' || bedrooms === 'N/A') {
    callback();
    return;
  }

  const data = await apiFetch('average-size', {
    method: 'post',
    returnError: true,
    body: JSON.stringify({ postcode, unitType, bedrooms, sqMeters: selectorSq_ft_Sq_m === 'Sq.m' }),
  });

  if (!('error' in data)) {
    callback(data.size);
  }
};

export const getAveragePricesStat = (): AppThunk => async (dispatch, getState) => {
  const postcode = getState().calculate.analysePage?.locationService?.getGeoCodes?.postcode;
  const country = parseResultData(getState().calculate.resultData)?.input?.country;
  const [longitude, latitude] = getState().calculate.analysePage?.locationService?.getGeoCodes?.geometry?.coordinates || [];

  const checkUK = country === 'UK' && !postcode;
  const checkSWE = country === 'SWE' && !longitude && !latitude;

  if (!country || checkUK || checkSWE) {
    return;
  }

  const data = await apiFetch('average-prices-stat', {
    method: 'post',
    returnError: true,
    body: JSON.stringify(country === 'UK' ? { country, postcode } : { country, longitude, latitude }),
  });

  if (!('error' in data)) {
    dispatch(setAveragePricesStat(data));
  } else {
    dispatch(setAveragePricesStat(null));
  }
};

export const createTeaserReport = (callback: (id: string) => void): AppThunk => async (dispatch, getState) => {
  const { projectId, scenarioId } = getState().calculate;

  const teaserReportId = randomBytes(12).toString('hex');

  dispatch(createScenario({ projectId, scenarioId, teaserReportId, callback: () => {
    callback(teaserReportId);
    dispatch(setTeaserReportId(teaserReportId));
  } }));
};

export const saveRiskProfile = (riskProfile: number): AppThunk => async (dispatch, getState) => {
  try {
    const { projectId, scenarioId } = getState().calculate;

    dispatch(createScenario({ projectId, scenarioId, riskProfile }));

  } catch (error) {
    console.error(error);
    toast('Unable to update scenario', { type: 'error' });
  }
};

export const getHubspotLists = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {

    const userId = getState().Auth.user?.id;

    if (!userId) {
      return;
    }

    const res = await apiFetch(`storage/hubspot/lists`, {
      method: 'get',
      headers: { userId },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    }

    dispatch(setHubspotLists(res));
    dispatch(setLoading(false));

  } catch (error) {
    console.error(error);
    toast('Unable to get lists', { type: 'error' });
  }
};

export const getHubspotContacts = (listId: number): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const userId = getState().Auth.user?.id;

    if (!userId) {
      return;
    }

    const res = await apiFetch(`storage/hubspot/list/contacts?listId=${listId}&count=400&page=1`, {
      method: 'get',
      headers: { userId },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    }
    dispatch(setHubspotContacts(res));
    dispatch(setLoading(false));

  } catch (error) {
    console.error(error);
    toast('Unable to get lists', { type: 'error' });
  }
};

export const shareReport = (id: string, type: 'teaserId' | 'scenarioId', emails: string[]): AppThunk => async (dispatch, getState) => {
  try {
    const userId = getState().Auth.user?.id;

    if (!userId) {
      return;
    }

    const res = await apiFetch(`storage/project/share`, {
      method: 'post',
      body: JSON.stringify({ [type]: id, email: emails }),
      headers: { 'Content-Type': 'application/json', userId },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    } else {
      toast('Report shared successfully');
    }

  } catch (error) {
    console.error(error);
    toast('Unable to share the report', { type: 'error' });
    dispatch(setLoading(false));
  }
};

interface GetLocalMarket {
  coordinates?: number[];
  region?: string;
  units?: Array<ResidentialUnit | CommercialUnit | OtherUnit>;
}

export const getLocalMarket = ({ coordinates, region, units }: GetLocalMarket): AppThunk => async (dispatch, getState) => {
  try {
    const [longitude, latitude] = coordinates || getState().calculate.analysePage?.locationService?.getGeoCodes?.geometry?.coordinates || [];

    const projectType = getState().calculate.analysePage?.editingData?.type_of_project || 'New build houses and apartments';
    const country = getState().calculate.analysePage?.editingData?.country;
    const unitsRequest = (units || getState().calculate.analysePage?.editingData?.units_array)
      .filter((unit) => (unit.type || 'residential') === 'residential' && (unit.property_type || 'Unspecified') !== 'Unspecified' &&
      !(['Unspecified', 'N/A'] as BedroomType[]).includes((unit as ResidentialUnit).bedroom_no || 'Unspecified') ) as ResidentialUnit[];

    const requestBody = {
      country,
      latitude,
      longitude,
      //TBD: change to region
      region: 'London',
      options: {
        minOffersInMonth: 30,
        monthCount: 12,
      },
    };

    if (unitsRequest.length > 0) {
      requestBody['units'] = unitsRequest.map((unit) => ({ type: unit.property_type, bedrooms: unit.bedroom_no }));
    } else {
      requestBody['projectType'] = projectType;
    }

    const res = await apiFetch('local-market', {
      method: 'post',
      body: JSON.stringify(requestBody),
      headers: { 'Content-Type': 'application/json' },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    }

    dispatch(setLocalMarket(res.data));
  } catch(error) {
    console.error(error);
  }
};

export const updateReport = (filesToUpload: File[], filesToDelete: string[], notesToUpload: Note[], callback: () => void): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setLoadStatus('load'));
    const userId = getState().Auth.user?.id;
    const scenarioId = getState().calculate.scenarioId;

    if (!userId) {
      return;
    }

    for (const file of filesToUpload || []) {
      try {
        await apiFetch(`storage/file/scenario/${scenarioId}?section=${encodeURIComponent(file.section)}&name=${encodeURIComponent(file.name)}&type=${file.type}`, {
          method: 'post',
          body: file.file,
          headers: { userId },
          returnError: true,
        });
      } catch (error) {
        console.error(error);
        toast('Error while saving file', { type: 'error' });
      }
    }

    await Promise.all(filesToDelete.map((fileId) => apiFetch(`storage/file/scenario/${fileId}`, {
      method: 'delete',
      headers: { userId },
      returnError: true,
    })));

    for (const note of notesToUpload || []) {
      try {
        await apiFetch('storage/note', {
          method: 'post',
          body: JSON.stringify({ ...note, scenarioId }),
          headers: { 'Content-Type': 'application/json', userId },
          returnError: true,
        });
      } catch (error) {
        console.error(error);
        toast('Error while saving note', { type: 'error' });
      }
    }

    callback();
    dispatch(setLoadStatus('success'));
  } catch(error) {
    console.error(error);
    toast('Error updating report', { type: 'error' });
    dispatch(setLoadStatus('error'));
  }
};

interface GetComparablesListProps {
  country: Country;
  page: number;
  limit: number;
  minPrice?: number;
  maxPrice?: number;
  types?: UnitType[];
  beds?: number[];
  saleStatus?: SaleStatus;
  dateOfSellMonths: DateOfSellMonth;
}

export const getComparablesListAction = (params: GetComparablesListProps): AppThunk => async (dispatch, getState) => {
  try {
    setIsBlockApisUsage(true);

    const coordinates = getState().calculate.analysePage.locationService?.getGeoCodes?.geometry?.coordinates;

    if (!coordinates) {
      throw new Error('Coordinates are not defined');
    }

    const [longitude, latitude] = coordinates;

    const { dateOfSellMonths, ...rest } = params;

    if (dateOfSellMonths !== 'All period') {
      rest['dateOfSellStart'] = new Date();
      rest['dateOfSellStart'].setMonth(rest['dateOfSellStart'].getMonth() - +dateOfSellMonths);
    }

    const response =  await apiFetch(`comparables/list`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ latitude, longitude, ...rest }),
    });

    if (response?.data) {
      dispatch(setComparablesList(response?.data?.properties));
      dispatch(setMaxComparables(response?.data?.count));
    } else {
      throw new Error('No data in response');
    }
  } catch (error) {
    console.error('Error, while getting comparables list', error);
  } finally {
    setIsBlockApisUsage(false);
  }
};

export const getLead = (id: string, type: 'create' | 'data', callback?: (address: string) => void): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setLoadStatus('load'));

    const res: LeadGetResponse = await apiFetch(`storage/lead/${id}`, {
      method: 'get',
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    }

    const assets = res.assets;

    const assetId = Array.isArray(assets) ? assets[0]?._id : assets?._id;

    const leadImages = ((Array.isArray(assets) ? assets.reduce((acc, cur) => [...acc, ...(cur?.marketPlaceListing?.coverImages || [])], []) :
      assets?.marketPlaceListing?.coverImages) || []) as CoverImage[];
    const value = Array.isArray(assets) ? assets[0]?.marketPlaceListing?.description : assets?.marketPlaceListing?.description;
    const date = Array.isArray(assets) ? assets[0]?.updatedAt : assets?.updatedAt;
    const userId = res.lead?.userId;

    const logs = value ? [{ date, userId, userName: res.lead?.leadUserName, action: 'create', field: 'assets.marketPlaceListing.description', value }] as Log[] : undefined;

    if (type === 'create') {
      dispatch(setEditingData({
        country: res?.lead?.country,
        site_address: res?.lead?.location?.address,
        site_purchase_price: res?.lead?.price || res?.lead?.plotPrice || undefined,
        total_floor_area: res?.lead?.totalFloorArea || res?.lead?.plotArea || undefined,
      }));
      dispatch(setLeadId(id));
      dispatch(setEditingData({ project_name: res.lead?.name }));

      if (logs) {
        dispatch(setLogs(logs));
      }
    }

    dispatch(setLeadName(res.lead?.name));
    dispatch(setLeadFiles(res.files?.map?.((file) => ({ ...file, name: file.sectionFilename || '' }))) || []);
    dispatch(setAssetsId(assetId));
    dispatch(setLeadImages(leadImages.map((item) => ({
      _id: item._id,
      name: item.filename,
      section: 'Lead',
      type: 'Photos',
      url: item.url,
      isCoverImage: item.isCoverImage,
      sequenceNumber: item.sequenceNumber,
    }))));

    callback?.(res?.lead?.location?.address);
    dispatch(setLoadStatus('success'));
  } catch (error) {
    console.error(error);
    toast('Unable to get lead', { type: 'error' });
    dispatch(setLoadStatus('error'));
  }
};

export const getLocalArea = (coordinates: number[]): AppThunk => async (dispatch, getState) => {
  try {
    const [longitude, latitude] = coordinates || getState().calculate.analysePage?.locationService?.getGeoCodes?.geometry?.coordinates || [];
    const country = getState().calculate.analysePage?.editingData?.country;

    const requestBody = {
      country,
      latitude,
      longitude,
    };

    const res = await apiFetch('local-area', {
      method: 'post',
      body: JSON.stringify(requestBody),
      headers: { 'Content-Type': 'application/json' },
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    }

    dispatch(setLocalArea({ data: res.localArea }));
    dispatch(setLocalAreaSchools(res.schools || []));
    dispatch(setLocalAreaStations(res.stations || []));
  } catch(error) {
    console.error(error);
  }
};

export const geocoding = (address: string, loadStatistics?: boolean): AppThunk => async (dispatch, getState) => {
  if (!address) {
    return;
  }

  dispatch(setIsBlockApisUsage(true));

  try {
    const country = getState().calculate.analysePage?.editingData?.country;

    const res = await apiFetch('geocode', {
      method: 'post',
      body: JSON.stringify({ country, address }),
      returnError: true,
    });

    if ('error' in res) {
      throw new Error(res.error);
    }

    dispatch(setLocationGeoCodes(res));

    if (loadStatistics) {
      dispatch(getLocalArea(res.geometry.coordinates));
      dispatch(getLocalMarket({ coordinates: res.geometry.coordinates }));
    }
  } catch (error) {
    console.error('Error, while geocoding', error);
  }

  dispatch(setIsBlockApisUsage(false));
};

export const createAsset = (callback?: (id: string) => void): AppThunk => async (dispatch, getState) => {
  try {
    const leadId = getState().calculate.leadId;
    const projectId = getState().calculate.projectId;

    const res: { _id: string } = await apiFetch('assets', {
      method: 'post',
      body: JSON.stringify({ leadId, projectId }),
      headers: { 'Content-Type': 'application/json' },
    });

    dispatch(setAssetsId(res._id));
    callback?.(res._id);
  } catch (error) {
    console.error(error);
  }
};

interface updateAssetProps {
  description?: string;
  projectName?: string;
}

export const updateAsset = ({ description, projectName }: updateAssetProps): AppThunk => async (dispatch, getState) => {
  const assetsId = getState().calculate.assetsId;

  const request = {
    ...(projectName ? { general: { projectName } } : {}),
    ...(description ? { marketPlaceListing: { description } } : {}),
  };

  if (Object.keys(request).length === 0) {
    return;
  }

  try {
    await apiFetch(`assets/update/${assetsId}`, {
      method: 'post',
      body: JSON.stringify(request),
      headers: { 'Content-Type': 'application/json' },
    });
  } catch (error) {
    console.error(error);
  }
};

export const uploadCoverImageToAsset = (assetId: string, file: File, isCoverImage: boolean, sequenceNumber?: number): AppThunk => async () => {
  try {
    await apiFetch(`assets/cover-image/${assetId}/${isCoverImage}/${sequenceNumber}`, {
      method: 'post',
      body: file.file,
      headers: {
        'Content-Type': 'image/png',
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteCoverImage = (id: string): AppThunk => async () => {
  try {
    await apiFetch(`assets/cover-image/${id}`, { method: 'delete' });
  } catch (error) {
    console.error(error);
  }
};

export const setImageAsCover = (id: string): AppThunk => async () => {
  try {
    await apiFetch(`assets/cover-image/${id}`, { method: 'post' });
  } catch (error) {
    console.error(error);
  }
};

export const createDocument = async (paramBody: CreateDocumentRequestType) => {
  try {
    const documentIds: string[] = await apiFetch('document', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: { 'Content-Type': 'application/json' },
    });
    return documentIds;
  } catch (error) {
    console.error(error);
  }
};

export const uploadDocument = async (file: FinanceUploadFileType): Promise<FinanceUploadFileType | undefined> => {
  try {
    const response: { url: string } = await apiFetch(`document/upload/${file._id}`, {
      method: 'post',
      headers: { 'Content-Type': file.file?.type || file.uploadingFileType || 'image/png' },
      body: file.file,
    });
    return {
      ...file,
      url: response.url,
    };
  } catch (error) {
    console.error(error);
  }
};

export const deleteDocument = async (documentId: string) => {
  try {
    await apiFetch(`document/delete/${documentId}/false`, { method: 'delete' });
  } catch (error) {
    console.error(error);
  }
};
