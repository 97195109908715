/* eslint-disable @typescript-eslint/indent */

import { Country, Currency, Unit } from "..";

export interface PaymentSchedule {
  [month: string]: number;
}

export interface PurchaseCostArray {
  cost_type: string;
  total_cost?: number;
  payment: string;
  payment_type?: string;
  payment_schedule?: PaymentSchedule;
}

export const defaultPurchaseCostItem: PurchaseCostArray = {
  cost_type: '',
  total_cost: undefined,
  payment: '',
  payment_type: '',
};

export type RepaymentType = 'single' | 'On exit' | '';

export const fundingSections = ['Debt funding', 'Equity', 'Other'] as const;
export type FundingSection = typeof fundingSections[number];

export const typeOfFinanceDebt = ['Senior debt', 'Junior debt', 'Bridge debt', 'Other debt', ''] as const;
export const typeOfFinanceEquity = ['JV Equity', "Developer's equity", ''] as const;
export const typeOfFinanceOther = ['Delayed payment', ''] as const;

const typeOfFinance = [...typeOfFinanceDebt, ...typeOfFinanceEquity, ...typeOfFinanceOther] as const;

export type TypeOfFinance = typeof typeOfFinance[number];

export const drawdowns = ['single', 'single-when-needed', 'periodic'] as const;
export type Drawdowns = typeof drawdowns[number];

export interface FundingArray {
  id?: number;
  type: TypeOfFinance;
  ratio: string;
  repayment_type: RepaymentType;
  drawdowns: string;
  percentage?: number;
  interest?: number;
  fees?: number;
  security: string;
  profit_share?: number;
  section: FundingSection;
  rolled_up_interest: boolean;
}

export const measurementUnitList = ['m', 'm2', 'unit', 'ton', 'hour', 'pack', 'litre'] as const;
export type MeasurementUnit = typeof measurementUnitList[number];

export const measurementUnitListSingle: Record<MeasurementUnit, string> = {
  m: 'meter',
  m2: 'sq meter',
  unit: 'unit',
  ton: 'ton',
  hour: 'hour',
  pack: 'pack',
  litre: 'litre',
};

export const measurementUnitListPlural: Record<MeasurementUnit, string> = {
  m: 'meters',
  m2: 'sqs meter',
  unit: 'units',
  ton: 'tons',
  hour: 'hours',
  pack: 'packs',
  litre: 'litres',
};

export interface ConstructionCost {
  construction_cost_category: 'Construction';
  cost_type: string;
  total_cost?: number;
  payment_type: string;
  payment_start: string;
  unit?: MeasurementUnit;
  unit_cost?: number;
  unit_quantity?: number;
  delayed_payment?: number;
  payment_schedule?: PaymentSchedule;
}

export interface PreConstructionCost {
  construction_cost_category: 'Pre-construction';
  cost_type: string;
  total_cost?: number;
  construction_cost?: number;
  cost_psqft?: number;
  payment_type: string;
  payment_start?: string;
  construction_cost_percent?: number;
  total_floor_area_cost?: number;
  delayed_payment?: number;
  payment_schedule?: PaymentSchedule;
}

export interface ProfessionalFeesCost {
  construction_cost_category: 'Professional fees';
  cost_type: string;
  total_cost?: number;
  construction_cost?: number;
  cost_psqft?: number;
  payment_type: string;
  payment_start?: string;
  construction_cost_percent?: number;
  total_floor_area_cost?: number;
  delayed_payment?: number;
  payment_schedule?: PaymentSchedule;
}


export const defaultConstructionCost = (date: string) => ({
  construction_cost_category: 'Construction' as const,
  cost_type: '',
  total_cost: undefined,
  payment_type: 'Monthly',
  payment_start: date,
});

export const defaultPreConstructionCost = (date: string) => ({
  construction_cost_category: 'Pre-construction' as const,
  cost_type: '',
  total_cost: undefined,
  construction_cost: undefined,
  cost_psqft: undefined,
  payment_type: '',
  payment_start: date,
});

export const defaultProfessionalFee = (date: string) => ({
  construction_cost_category: 'Professional fees' as const,
  cost_type: '',
  total_cost: undefined,
  construction_cost: undefined,
  cost_psqft: undefined,
  payment_type: '',
  payment_start: date,
});

export interface ContingencyCost {
  total?: number;
  percent_construction_cost?: number;
  prsqf_of_nia?: number;
}

export interface SalesCostArray {
  cost_type: string;
  total_cost?: number;
  payment?: string;
  percent_total_sales?: number;
}

export const defaultSalesItem: SalesCostArray = {
  cost_type: '',
  total_cost: undefined,
  payment: '',
};

export type ConstructionCosts = ConstructionCost | PreConstructionCost | ProfessionalFeesCost;

export type TypeOfProject = 'New build apartments' |
  'New build houses' |
  'New build houses and apartments' |
  'Investment' |
  'Conversion' |
  'Permitted development' | '' | undefined;

export type UnitValuationType = 'bm' | 'commercial';

export const valuationTypeLabels = {
  bm: 'B&M valuation',
  commercial: 'Commercial valuation',
};

export interface InputDataContextInterface {
  project_name: string;
  project_start_purchase: number;
  project_purchase_construction: number;
  site_address: string;
  country: Country;
  currency: Currency;
  type_of_project: TypeOfProject;
  site_purchase_price?: number;
  gdv?: number;
  number_of_units?: number;
  build_cost_psqft?: number;
  total_floor_area?: number;
  total_construction_cost?: number;
  project_start_date: string;
  build_time_months?: number;
  site_type: string;
  buyer_type: string;
  stamp_duty_type: string;
  additional_purchase_costs?: number;
  planning_status: string;
  planning_application_url: string;
  purchase_costs_array: PurchaseCostArray[];
  units_array: Unit[];
  "nia/gea"?: number;
  "nia/gea_cost_ratio"?: number;
  contract_type: string;
  construction_type: string;
  finish_type: string;
  contingency?: number;
  contract_deposit?: number;
  payment_type: string;
  construction_costs_array: ConstructionCosts[];
  total_of_loans?: number;
  equity_required?: number;
  delayed_payment?: number;
  jv_equity?: number;
  funding_array: FundingArray[];
  sales_strategy: string;
  completion_date: string;
  start_of_sales: string;
  sales_complete: string;
  show_home_unit: string;
  show_home_value?: number;
  total_sales_cost?: number;
  sales_costs_array: SalesCostArray[];
  contingency_array: ContingencyCost[];
  contingency_total?: number;
  use_periodic_drawdowns?: boolean;
  residential_valuation_type: UnitValuationType;
  delayed_payment_purchase?: number;
}
