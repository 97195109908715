export const logActions = ['create', 'update', 'request'] as const;
export type LogAction = typeof logActions[number];

export const actionMapping: Record<LogAction, string> = {
  'create': 'Created by',
  'update': 'Edited by',
  'request': 'Recalculation requested by',
}

const logFields = ['assets.marketPlaceListing.description'] as const;
type LogField = typeof logFields[number];

export const fieldMapping: Record<LogField, string> = {
  'assets.marketPlaceListing.description': 'Basic Details',
}

export interface LogFinancingRequest {
  section: string;
  totalFunds: number;
  fee: number;
  ltc: number;
  ltgdv: number;
  profitShare: number;
  interestRate: number;
  debtRequestId: string;
  partnerId: string;
}

export interface Log {
  _id?: string;
  date: string;
  userId: string;
  action: LogAction;
  field?: LogField;
  value?: string;
  applied?: boolean;
  message?: string;
  userName?: string;
  isRecalculate?: boolean;
  constructionRequest?: number;
  financingRequest?: LogFinancingRequest;
}
